import { observer } from 'mobx-react'
import { Box, Flex } from 'theme-ui'
import ScrollLock from 'react-scrolllock'

import { motion, AnimatePresence } from 'framer-motion'

import { useStores } from '@/stores'

import { Link } from 'gatsby'
import Burger from './Burger'

const speed = 0.25

const Menu = observer(() => {
  const { appStore } = useStores()

  return (
    <>
      <ScrollLock isActive={appStore.isMenuOpen}></ScrollLock>
      <motion.div
        sx={{
          width: '100vw',
          height: '100vh',
          bg: 'blue',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 'menu',
        }}
        initial={{ translateY: '-100%' }}
        animate={{ translateY: appStore.isMenuOpen ? '0%' : '-100%' }}
        transition={{ ease: 'easeInOut', duration: speed }}
      >
        <Flex
          sx={{
            px: 1,
            alignItems: 'center',
            height: '72px',
            justifyContent: 'flex-end',
          }}
        >
          <Burger isOpen />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            ml: 3,
            position: 'absolute',
            bottom: 0,
            mb: 3,
            left: 0,
            gap: 0,
            fontSize: ['12vh'],
            lineHeight: ['12vh'],
            textAlign: 'left',
          }}
        >
          {' '}
          <AnimatePresence>
            {appStore.isMenuOpen && (
              <>
                <motion.div
                  sx={{ pr: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: speed,
                    delay: speed,
                  }}
                >
                  <Link
                    to="/work"
                    sx={{
                      color: '#fff !important',
                      textDecoration: 'none',
                      fontFamily: 'heading',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Work
                  </Link>
                </motion.div>
                {/* <motion.div
                  sx={{ pr: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: speed,
                    delay: speed * 1.5,
                  }}
                >
                  <Link
                    to="/news"
                    sx={{
                      color: '#fff !important',
                      textDecoration: 'none',

                      fontFamily: 'heading',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    News
                  </Link>
                </motion.div> */}
                <motion.div
                  sx={{ pr: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.25,
                    delay: speed * 1.5,
                  }}
                >
                  <Link
                    to="/about"
                    sx={{
                      color: '#fff !important',
                      textDecoration: 'none',

                      fontFamily: 'heading',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    About
                  </Link>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </Flex>
      </motion.div>
    </>
  )
})

export default Menu
